import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Image from "gatsby-image"

import Layout from "../components/Layout"
import CardV3 from "../components/CardV3/CardV3"

const Index = () => {
  const images = useStaticQuery(graphql`
    query ImagesHome {
      mainImage: file(relativePath: { eq: "historia-2.jpeg" }) {
        id
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      riego: file(relativePath: { eq: "tuberias.jpg" }) {
        id
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      bombeo: file(relativePath: { eq: "suministro-agua-domestica.png" }) {
        id
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      energiaFotovoltaica: file(
        relativePath: { eq: "energia-fotovoltaica-1.jpeg" }
      ) {
        id
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      canalizacionAguas: file(relativePath: { eq: "canalizacion-aguas.jpeg" }) {
        id
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      jardineria: file(relativePath: { eq: "jardineria-2.jpg" }) {
        id
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
    }
  `)

  return (
    <Layout>
      <div className="container">
        <div className="margin-y-lg">
          <div className="grid gap-xl items-center">
            <div className="col-12 col-6@sm order-2 order-1@sm">
              <div className="text-component">
                <h1 className="text-xl">Riegos Azuer</h1>
                <p>
                  <span className="font-bold">Somos</span> una empresa española
                  que contribuye al progreso y a la mejora de la calidad de vida
                  a través del desarrollo de nuevas tecnologí­as y materiales de
                  gran calidad en el manejo del agua.
                </p>
                <p>
                  <span className="font-bold">Creemos</span>en el modelo de
                  empresa que aporte total satisfacción a nuestros clientes y
                  usuarios mediante proyectos y diseños, que con el mí­nimo
                  consumo energético aumente los rendimientos en cultivos y
                  doten de infraestructuras modernas a los sectores implicados.
                </p>
                <p>
                  <span className="font-bold">Nos comprometemos</span>, con el
                  personal adecuado, a ofrecer el servicio técnico necesario que
                  hagan funcionar los equipos a plena satisfacción, tanto en el
                  mantenimiento de estos equipos como con un profundo respeto al
                  medio ambiente.
                </p>
              </div>
            </div>
            <div className="col-12 col-6@sm order-1 order-2@sm">
              <Image fluid={images.mainImage.childImageSharp.fluid} />
            </div>
          </div>
        </div>
        <div className="margin-y-xxl">
          <div className="grid gap-md">
            <div className="col-12 col-6@sm col-3@md">
              <CardV3
                title="Riegos"
                image={images.riego.childImageSharp.fluid}
                url="/riegos"
              />
            </div>
            <div className="col-12 col-6@sm col-3@md">
              <CardV3
                title="Bombeo"
                image={images.bombeo.childImageSharp.fluid}
                url="/bombeo"
              />
            </div>
            <div className="col-12 col-6@sm col-3@md">
              <CardV3
                title="Energía Fotovoltaica"
                image={images.energiaFotovoltaica.childImageSharp.fluid}
                url="/energia-fotovoltaica"
              />
            </div>
            <div className="col-12 col-6@sm col-3@md">
              <CardV3
                title="Canalización de aguas"
                image={images.canalizacionAguas.childImageSharp.fluid}
                url="/canalizacion-aguas"
              />
            </div>
            <div className="col-12 col-6@sm col-3@md">
              <CardV3
                title="Jardinería"
                image={images.jardineria.childImageSharp.fluid}
                url="/jardineria"
              />
            </div>
            <div className="col-12 col-6@sm col-3@md">
              <CardV3
                title="Consejos"
                image={images.mainImage.childImageSharp.fluid}
                url="/consejos/montaje-cesped-jardin"
              />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Index
